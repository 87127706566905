/* Next DTC */
body {
   max-width: 1600px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    animation: change-background 8s linear infinite;
    -webkit-animation: change-background 20s infinite; 
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    
}

@keyframes change-background {
  0%   {background: rgb(250, 209, 230);}
  25%  {background: rgb(216,221,255);}
  50%  {background: rgb(209, 250, 240);}
  75% {background: rgb(219, 209, 250);}
  100% {background: rgb(247, 212, 230);}
}

.home-page-wrap {
  padding: 25vh 0 10vh;
  margin: 0 auto;
  height: 100%;
  min-height: 100vh;

  @include media-query($bp__sm) {
      padding: 5vh 0 10vh;
    }
}

.omg-logo {
  width: 55%;
  margin: 0 auto;
}

.title-wrap {
  margin: 0 0 0 4rem;
  width: 55%;

    @include media-query($bp__sm) {
      width: 80%;
    }
  
  h1 {
    font-size: 3.5rem;
    font-family: 'Reckless Regular';
    color: #fff;
    line-height: 1.2;
    text-align: left;
    text-transform: none;
    letter-spacing: -2px;

    @include media-query($macbook) {
      font-size: 3.25rem;
    }

    @include media-query($on_laptop) {
      font-size: 4vw;
    }

    @include media-query($bp__sm) {
      font-size: 3rem;
    }

  }

  span.name {
    font-family: 'Reckless RegularItalic';
  }

  span.italic-reg {
    font-family: 'Reckless Semibold';
  }

  a {
    display: inline-block;
    color: #000;
    letter-spacing: -2px;
  }

  
}

.contact-wrap {
  width: 60%;
  margin: 0 auto;

  fieldset {
    border: none;
  }

  label {
    padding: 1rem 0 1rem;
    display: block;
  }

  textarea {
    border: 1px solid #0c0c0c;
    background-color: transparent;
    padding: 1.75em 1em;
    margin-bottom: 1.5em;
    font-family: Work Sans, Arial, Helvetica, sans-serif !important;
    font-size: 14px;
    letter-spacing: .5px;
    padding-top: 5px;
    color: #0c0c0c;
    line-height: 1.42;
  }

  button {
    margin: 0px 30px 25px 0;
    cursor: pointer;
    position: relative;
    background-color: #0c0c0c;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 15px;
    font-size: 14px;
    letter-spacing: .7px;
    border: 1px solid #0c0c0c;
  }
}