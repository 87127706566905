/* ==========================================================================
   Variabeles
   ========================================================================== */

$c-base__03: #000;
$c-base__02: #000;
$c-base__01: #5C6E74;
$c-base__00: #697B82;
$c-base__0: #869395;
$c-base__1: #96A0A0;
$c-base__2: #EDE7D6;
$c-base__3: #FCF5E4;

$c-accent__blue: #42B6F3;
$c-accent__darkblue: #0029F9;
$c-accent__green: #8BE367;

// Breakpoints
$small: 660px;
$medium: 893px;
$large: 1060px;
$on-palm: 600px;
$bp__sm: 630px;
$on-se: 374px;

$on-phone: 600px;
$on-ipad: 900px; 
$on-laptop: 1100px;
$macbook: 1460px;
$imac: 1800px;



// Misc
$m-border: 1px solid $c-base__2;
