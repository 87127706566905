/* ==========================================================================
   Archives
   ========================================================================== */

.c-archives {
    margin-bottom: 10rem;
}

.c-archives__year {
    margin-bottom: .5rem;
    line-height: 1.2;
    font-size: 2.2rem;
}

.c-archives__list {
    margin-bottom: 3.5rem;
    list-style: none;
}

.c-archives__item {
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 1.5rem;
    @media screen and (min-width: $bp__sm) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
    }

    h3 {
        line-height: 1.1;
        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    p {
        @include fs--body;
        color: #515862;
    }
}


.kwes-form input{
    border: 1px solid #0c0c0c;
    background-color: transparent;
    padding: 1.5em 1em;
    width: 100%;
    margin: 0 auto 2.5rem;
}