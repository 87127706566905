/* Final Daya Gibb css */

/*=========================================
 Mobile Navigation
 ==========================================*/








/*=========================================
 Desktop Navigation
 ==========================================*/

header {
}

.site-logo-module {
  display: block;
  text-align: center;
  padding: 4rem 0 2.5rem;

  @include media-query($imac) {
    padding: 4rem 0 2.5rem;
  }

  @include media-query($macbook) {
    padding: 4rem 0 2.5rem;
  }

  @include media-query($on-laptop) {
    padding: 4rem 0 2.5rem;
  }

  @include media-query($on-ipad) {
    padding: 4rem 0 2.5rem;
  }

  @include media-query($on-phone) {
    padding: 4rem 0 2rem;
  }
}

.site-logo-module-middle {
  display: block;
  text-align: center;
  padding: 25% 0 1rem;

  @include media-query($on-phone) {
    padding: 8rem 0 1rem;
  }
}

.site-logo {
  text-align: center;

  a {
    display: block;
  }
}

.alisha-nav {

  padding-bottom: 2rem;
  padding-top: 1rem;


  @include media-query($on-ipad) {
    padding-bottom: 2rem;
  }

  @include media-query($on-phone) {
    padding-bottom: 2rem;
  }

  nav {
    display: block;
    float: none;
    text-align: center;
  }

  li {
    display: inline-block;
  }

  .mobile-link {
    @include media-query($bp__sm) {
      display: none;
    }
  }

  a {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    color: #0c0c0c;
    font-family: 'Engravers Gothic',Arial,Helvetica,sans-serif;
    letter-spacing: 2px;
    padding: 5px 35px 0 35px;
    font-size: 15px;
    letter-spacing: .8px;

    @include media-query($imac) {
    font-size: 15px;
    }

    @include media-query($macbook) {
    font-size: 15px;
    }

    @include media-query($on-laptop) {
    font-size: 15px;
    }

    @include media-query($on-ipad) {
    font-size: 15px;
    }

    @include media-query($on-phone) {
    font-size: 15px;
    padding: 0 20px 0 20px;
    }

    @include media-query($bp__sm) {
    font-size: 11px;
    padding: 0 15px 0 15px;
    }
  }
}




/*=========================================
  Home Page
 ==========================================*/

.home-page-module {
  width: 90%;
  margin: 0 auto;
  max-width: 1400px;
  padding-top: 2rem;

  @include media-query($imac) {
    width: 90%;
  }

  @include media-query($macbook) {
    width: 90%;
  }

  @include media-query($on-laptop) {
    width: 90%;
  }

  @include media-query($on-ipad) {
    width: 80%;
  }

  @include media-query($on-phone) {
    width: 90%;
  }
}



.hero-image-wrap {
  margin-right: auto;
  margin-left: auto;
  flex: 1 0 auto;
  display: flex;
  align-items: start;
  justify-content: center;
  max-width: 70%;
  padding-top: 1rem;
}


.home-footer {
  padding-top: 0;
}




/*=========================================
 Content Pages - Services, About, Contact
 ==========================================*/

.main-wrap {
  padding-top: 5rem;
  width: 100%;

  @include media-query($imac) {
    display: block;
    width: 100%;
  }

  @include media-query($macbook) {
   width: 85%;
    display: flex;
    margin: 0 auto;
    align-items: start;
    justify-content: space-evenly;
  }

  @include media-query($on-laptop) {
    width: 90%;
    display: flex;
    margin: 0 auto;
    align-items: start;
    justify-content: space-evenly;
  }

  @include media-query($on-ipad) {
    display: block;
    width: 100%;
  }

  @include media-query($on-phone) {
    display: block;
    width: 100%;
  }
}

.first-block {
  display: block;
  margin: 0 auto;
  width: 60%;

  @include media-query($imac) {
    width: 60%;
    padding-right: 0;
    padding-bottom: 1em;
  }

  @include media-query($macbook) {
    width: 49%;
    flex: 0 1 auto;
    padding-right: 1em;
  }

  @include media-query($on-laptop) {
    width: 49%;
    flex: 0 1 auto;
    padding-right: 1em;
  }

  @include media-query($on-ipad) {
    width: 60%;
    padding-right: 0;
    padding-bottom: 1em;
  }

  @include media-query($on-phone) {
    width: 85%;
    padding-right: 0;
    padding-bottom: 1em;
  }
}

.image-wrap {

  width: 75%;
  margin: 0 auto; 
  padding-top: 2rem;

  img {

  }
}

.second-block {
  display: block;
  margin: 0 auto;
  padding-top: 4rem;
  padding-bottom: 3rem;
  width: 55%;

  @include media-query($imac) {
    width: 53%;
  }

  @include media-query($macbook) {
    width: 50%;
    flex: 0 1 auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: .5em;
  }

  @include media-query($on-laptop) {
    width: 50%;
    flex: 0 1 auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: .5em;
  }

  @include media-query($on-ipad) {
    width: 60%;
  }

  @include media-query($on-phone) {
    width: 85%;
    padding-left: 0px;
  }
}

.text-wrap {

  font-size: 12px;

  .heading-one {
    font-family: 'Gotham Book', Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 4rem;

    @include media-query($imac) {
      font-size: 1.2rem;
      padding-bottom: 3rem;
    }

    @include media-query($macbook) {
      font-size: 1.2rem;
      padding-bottom: 2rem;
      text-align: left;
    }

    @include media-query($on-laptop) {
      font-size: 1.1rem;
      padding-bottom: 2rem;
    }

    @include media-query($on-ipad) {
      font-size: 1.1rem;
      padding-bottom: 2rem;
      text-align: center;
    }

    @include media-query($on-phone) {
      font-size: 1.1rem;
      text-align: left;
      padding-bottom: 2rem;
    }
  }

  .blurb-subtitle {
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 1.2rem;
    line-height: 1.8;
  }



  .normal-para {

    a:hover {
      text-decoration: underline;
    }

    a {
      color: #0c0c0c;
    }

    p {

    font-family: 'Portrait',Georgia,serif;
    margin-bottom: 25px;
    display: block;
    font-size: 1.4rem;
    letter-spacing: .5px;
    padding-top: 5px;
    color: #0c0c0c;
    line-height: 1.42;

        @include media-query($imac) {
        
        text-align: left;
        }


        @include media-query($on-ipad) {
        
        text-align: left;
        padding-bottom: 1.5em;
        }



        @include media-query($on-phone) {
        font-size: 14px;
        line-height: 1.8;
        text-align: left;
        padding-bottom: 1.5em;
        }

    }
  }

}

h1 {
  margin: 0 0 5px;
    display: block;
    font-family: 'Engravers Gothic',Arial,Helvetica,sans-serif;
    font-size: 28px;
    letter-spacing: 1.1px;
    color: #444;
    text-transform: uppercase;
    line-height: 32px;
    text-align: center;
    padding: 3rem 0 4rem;
}

.properties-page {
  width: 80%;
  margin: 0 auto;

  @include media-query($bp__sm) { 
    width: 95%;
  }


 .lr p {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  margin: 0;
 }
}

.property {
  position: relative;
  margin-bottom: 10px;

  img {
  height: 120px;
 }

 .object-fit_scale-down { 
   object-fit: cover;
  }


p { font-weight: 200; font-size: 13px; margin-bottom: 10px; margin-top: 0;}

img { height: 420px; background-color: #444;}

img[class] { 
  width: 100%;
}

.original-image {
  margin-bottom: 50px;
}

.image {
  float: left;
  width: 100%;
  margin: 0;
  
  &:nth-child(2n) {
    clear: left;
  }
  
  &:nth-child(2n+1){
    margin-right: 0;
  }
}
 
}

.cover {
  width: 260px;
  object-fit: cover;
}

.property_details {
  position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 100px 30px 30px 30px;
}



.prop-text {
  h1 {
    margin: 1em 0 0;
    letter-spacing: .015em;
    line-height: 120%;
    display: block;
    font-family: 'Portrait Italic',Georgia,serif;
    font-size: 3rem;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    text-transform: initial;
    color: #0c0c0c;
    padding: 0 0 3rem;
    text-align: center;
  }

  .straight-suburb {
    font-family: 'Portrait',Georgia,serif;
  }

  h2 {
    margin-bottom: 20px;
    line-height: 1.42857;
    display: inline-block;
    font-size: 18px;
    color: #0c0c0c;
    letter-spacing: 1.1px;
    padding-bottom: 2rem;
    font-family: Work Sans,Arial,Helvetica,sans-serif!important;
  }

  h4 {
    font-family: Work Sans,Arial,Helvetica,sans-serif!important;
    line-height: 1.42857;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: .5px;
    color: #0c0c0c;
    text-transform: uppercase;
  }

  p {
    font-family: 'Portrait',Georgia,serif;
    margin-bottom: 25px;
    display: block;
    font-size: 1.8rem;
    letter-spacing: .5px;
    padding-top: 5px;
    color: #0c0c0c;
    line-height: 1.42;
  }
}

.cta-prop {
  a {
    width: 100%;
    
  }
}

.mm-black {
  margin: 25px auto 15px;
  cursor: pointer;
    position: relative;
    background-color: #0c0c0c;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 5px 15px 5px;
    font-size: 14px;
    letter-spacing: .7px;
    font-family: 'Engravers Gothic',Arial,Helvetica,sans-serif;
    border: 1px solid #0c0c0c;
}

.heading-journal {
    font-family: 'Gotham Book', Helvetica, sans-serif;
    text-transform: uppercase;
    font-size: 1.4rem;
    text-align: center;
    padding-bottom: 4rem;

    @include media-query($imac) {
      font-size: 1.2rem;
      padding-bottom: 3rem;
    }

    @include media-query($macbook) {
      font-size: 1.2rem;
      padding-bottom: 2rem;
      text-align: center;
    }

    @include media-query($on-laptop) {
      font-size: 1.1rem;
      padding-bottom: 2rem;
    }

    @include media-query($on-ipad) {
      font-size: 1.1rem;
      padding-bottom: 2rem;
      text-align: center;
    }

    @include media-query($on-phone) {
      font-size: 1.1rem;
      text-align: left;
      padding-bottom: 2rem;
    }
  }



/* ==========================================================================
   Tip
   ========================================================================== */

.c-tags {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
}

.c-tags li {
  float: left;
}

.c-tag {
  background: #eee;
  border-radius: 3px 0 0 3px;
  color: #999;
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 20px 0 23px;
  position: relative;
  margin: 0 10px 50px 0;
  text-decoration: none;
  -webkit-transition: color 0.2s;
}

.c-tag::before {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: '';
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}

.c-tag::after {
  background: #fff;
  border-bottom: 13px solid transparent;
  border-left: 10px solid #eee;
  border-top: 13px solid transparent;
  content: '';
  position: absolute;
  right: 0;
  top: 0;
}

.c-tag:hover {
  background-color: $c-accent__blue;
  color: white;
}

.c-tag:hover::after {
   border-left-color: $c-accent__blue;
}

.c-c-tag {
    margin-right: 1rem;
    position: relative;
    white-space: nowrap;
    @include fs--body;
    &:before {
        color: $c-base__0;
        content: '#\2009';
    }
}

.properties-pg {
  width: 80%;
  margin: 0 auto;

  @include media-query($bp__sm) {
    width: 95%;
  }

  .list-address {
    display: block;

    h3 {
      font-family: 'Portrait Italic',Georgia,serif;
      font-size:  1.8rem;
      letter-spacing: .015em;
      line-height: 120%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      text-transform: initial;
      color: #0c0c0c;
      text-align: center;
      padding-top: 3rem;
    }
  }

  .straight-suburb {
    font-family: 'Portrait',Georgia,serif;
  }

  .list-details {
    display: flex;
    justify-content: center;
    padding: 1rem 0 2rem;
  }

  .tag {
    letter-spacing: .1rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-family: 'Engravers Gothic',Arial,Helvetica,sans-serif;
    padding: 0 1em 4px;
    text-align: center;
  }

  .cta-prop {
    display: block;
    text-align: center;
  }

  .prop-pic-links {
    display: block;
  }
}


.list-hero-pics {
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  max-width: 940px;
  padding-bottom: 1rem;

  .img-wrap {
    width: 45%;
  }

  img {
    height: auto;
  }
}

.listing-photos {
  height: auto;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
  max-width: 1140px;
  padding: 2rem 0;

  @include media-query($bp__sm) {
    padding: 2rem 0 0;
  }

  .img-wrap {
    width: 45%;
  }

  img {
    height: auto;
  }
}

.single-pic {
 height: auto;
 display: block;
 margin: 0 auto;
 max-width: 1068px;
 padding: 2rem 0;
 width: 95%;
 
  @include media-query($bp__sm) {
    padding: 2rem 0 0;
  }
 
}

.block-that {
  max-width: 145px;
  display: block;
  margin: 0 auto;
}